import { TemplateHandler } from 'easy-template-x';
import moment from 'moment';
import { APIOrder } from '../../app/entities/order';
import { APIOrderLine } from '../../app/entities/orderLine';
import { buildOrderNumber } from '../../app/mappers';
import { OrderCustomerType, OrderPayingMethod, OrderShippingMethod, OrderTypes } from './enums';

// TODO: Srediti mapiranja ili generisati dokument na serveru
export const printDocument = async (order: APIOrder, orderLinesList: Array<APIOrderLine>) => {
    const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/templates/dispatch_note_template.docx`,
    );
    const templateFile = await response.blob();

    const orderLines = orderLinesList.map((orderLine, i) => {
        return {
            orderLineNumber: i + 1,
            uniqueId: orderLine.product.uniqueId || '',
            title: orderLine.product.title || '',
            price: `${orderLine.product.price}.00`,
            priceUnit: orderLine.priceUnit || '',
            discount: orderLine.discount ? `${Math.round(orderLine.discount)}%` : '',
            quantity: orderLine.quantity,
            orderLineTotalPrice: `${Math.round(orderLine.afterDiscountPrice)}.00`,
        };
    });
    const data = {
        orderNumberWithPrefix: buildOrderNumber(order.orderNumber),
        customerDetails: buildCustomerDetailsForDocument(order),
        companyDetails: buildCompanyDetailsForDocument(order),
        companyDetailsAvailable: order.payingMethod === OrderPayingMethod.LEGAL,
        shippingDetails: buildShippingDetailsForDocument(order),
        shippingDetailsAvailable: order.shippingMethod === OrderShippingMethod.SHIPPING,
        orderLines: orderLines,
        totalPrice: `${order.totalPrice}.00`,
        afterDiscountPrice: `${order.afterDiscountPrice}.00`,
        totalDiscount: order.totalDiscount ? `${order.totalDiscount}%` : '',
        totalDiscountAvailable: !!order.totalDiscount,
        createdAt: moment(order.created).locale('sr').format('DD MMM, YYYY'),
    };

    const handler = new TemplateHandler();
    const doc = await handler.process(templateFile, data);
    saveFile(buildOrderNumber(order.orderNumber).toLocaleLowerCase().concat('.docx'), doc);
};

const buildCustomerDetailsForDocument = (order: APIOrder) => {
    if (order.customerType === OrderCustomerType.NEW) {
        const customerEmail = order.customerEmail;
        if (!customerEmail) {
            return `${order.customerName}`;
        }
        return `${order.customerName}\n${customerEmail}`;
    } else {
        return `${order.customer?.name}\n${order.customer?.email}`;
    }
};

const buildCompanyDetailsForDocument = (order: APIOrder) => {
    return `${order.companyName}\nMatični broj: ${order.companyNumber}\nPIB: ${order.companyTaxNumber}\n${order.companyAddress}, ${order.companyZip} ${order.companyTown}\n${order.companyPhone}`;
};

const buildShippingDetailsForDocument = (order: APIOrder) => {
    // TODO: when online orders get refactor this should be removed
    if (order.orderType === OrderTypes.ONLINE) {
        return '';
    }
    if (order.email) {
        return `${order.name ?? ''}\n${order.email ?? ''}\n${order.address ?? ''}, ${
            order.zip ?? ''
        } ${order.town ?? ''}, ${order.country ?? ''}\n${order.phone ?? ''}`;
    }
    return `${order.name ?? ''}\n${order.address ?? ''}, ${order.zip ?? ''} ${order.town ?? ''}, ${
        order.country ?? ''
    }\n${order.phone ?? ''}`;
};

function saveFile(fileName: string, blob: Blob) {
    const blobUrl = URL.createObjectURL(blob);
    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

    a.href = blobUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(blobUrl);
}
